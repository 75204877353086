/* global angular */

'use strict';

angular.module('managerApp').factory('$_vehicle', function (procedureService) {
    return {
        isValidRegNum: function (registrationNum, returnType = false) {
            if (returnType !== true) {
                return this.isFNI(registrationNum) || this.isSIV(registrationNum);
            } else {
                if (this.isFNI(registrationNum)) {
                    return 'fni';
                } else if (this.isSIV(registrationNum)) {
                    return 'siv';
                } else {
                    return null;
                }
        }
        },
        isFNI: function (registrationNum) {
            let regNum = ('' + registrationNum).toUpperCase();
            return /^\d{1,4} [A-Z]{1,3} [A-Z0-9]{1,3}$/.test(regNum);
        },
        isSIV: function (registrationNum) {
            let regNum = ('' + registrationNum).toUpperCase();
            return /^[A-Z]{1,2}(-| )\d{1,3}(-| )[A-Z]{1,2}$/.test(regNum);
        },
        isValidFormulaNum: function (val) {
            if (typeof val === 'string') {
                val = val.toUpperCase();
            } else {
                return null;
            }
            return /^\d{4}[A-Z]{2}\d{5}$/.test(val);
        },
        getVehicleTypes: function () {
            let vehicleTypes = JSON.parse(localStorage.getItem('vehicleTypes'));
            if (vehicleTypes) {
                return Promise.resolve(vehicleTypes);
            }
            return new Promise((resolve, reject) => {
                procedureService.getVehicleTypes().then((data) => {
                    localStorage.setItem('vehicleTypes', JSON.stringify(data));
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getFuelTypes: function () {
            let fuelTypes = JSON.parse(localStorage.getItem('fuelTypes'));
            if (fuelTypes) {
                return Promise.resolve(fuelTypes);
            }
            return new Promise((resolve, reject) => {
                procedureService.getFuelTypes().then((data) => {
                    localStorage.setItem('fuelTypes', JSON.stringify(data));
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            });
        }
    };
});